@import './_variables';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: $scale;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--surface-ground);
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.layout-wrapper {
  min-height: 100vh;
}


.big {
  width: 2.5rem;
  height: 2.5rem;
}

.big .p-checkbox-box {
  display: inline;
  width: 2.5rem;
  height: 2.5rem;
}

.p-checkbox.big .p-checkbox-box .p-checkbox-icon {
  width: 2rem;
  height: 2rem;

}

.produtos_style .p-listbox-list {
  padding: 0
}

.produtos_style .p-listbox-item {
  border: 1px solid var(--surface-border) !important;
}


