@font-face {
  font-family: 'playtravel';
  src: url('./fonts/play.eot');
  src: url('./fonts/play.eot#iefix') format('embedded-opentype'),
  url('./fonts/play.ttf') format('truetype'),
  url('./fonts/play.woff') format('woff'),
  url('./fonts/play.svg#playtravel') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.piplay {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'playtravel', emoji !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.playTravelIcon {
  &:before {
    content: '\e900';
  }
}
