@import './_variables';

.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 7rem 2rem 2rem 4rem;
  transition: margin-left $transitionDuration;
}

.layout-main {
  flex: 1 1 auto;
  width: 100%;
}


.layout-theme-dark {
  background: rgb(0, 15, 0);
  background: -moz-linear-gradient(180deg, rgb(0, 15, 0, 1) 40%, rgba(0, 0, 0, 1) 90%);
  background: -webkit-linear-gradient(180deg, rgba(0, 15, 0, 1) 40%, rgba(0, 0, 0, 1) 90%);
  background: linear-gradient(180deg, rgba(0, 15, 0, 1) 40%, rgba(0, 0, 0, 1) 90%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3c6eb4", endColorstr="#3c95b4", GradientType=1);

}


.layout-theme-light {
  background: rgba(222, 255, 222, 1);
  background: -moz-linear-gradient(180deg, rgba(222, 255, 222, 1) 30%, rgba(256, 256, 256, 1) 90%);
  background: -webkit-linear-gradient(180deg, rgba(222, 255, 222, 1) 30%, rgba(256, 256, 256, 1) 90%);
  background: linear-gradient(180deg, rgba(222, 255, 222, 1) 30%, rgba(256, 256, 256, 1) 90%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3c6eb4", endColorstr="#3c95b4", GradientType=1);
}
