@import './_variables';
.layout-footer {
    transition: margin-left $transitionDuration;
    padding-top: 1rem;
    border-top: 1px solid var(--surface-border);
}


  .layout-footer-logo {
        display: flex;
        align-items: center;
        color: var(--surface-900);
        font-size: 1.5rem;
        font-weight: 500;
        width: 6rem;
        border-radius: 6px;

        img {
            height: 1.25rem;
            margin-right: .25rem;
        }

        &:focus {
            @include focused();
        }
    }
