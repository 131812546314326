/* Utils */
@import './_variables';
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}


@media (max-width: 600px){

    .small_list{
        display:list-item;
    }

}

label {
  margin-top:auto;
  margin-bottom:auto;
  font-weight: 600;
  color: var(--primary-color);
}




.monospaced {
    font-family: monospace;
}
